.divider {
  border-left: 1px solid var(--hl-color);
  height: auto;
  opacity: 0.2;
}

.row-divider {
  border-bottom: 1px solid var(--hl-color);
  width: 100%;
  opacity: 0.2;
  margin: auto;
  margin-bottom: 3rem;
  display: block;
}

.blogCard {
  padding: 0 12rem;
}

.blogTitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 2em;
  font-weight: 300;
  margin-bottom: 1.25em;
  color: var(--hl-color);
}

.blogAuthor {
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: 2.5em;
  font-size: 14px;
  color: #aaa;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
}

.blogDesc {
  margin: var(--spacing) 0;
}
