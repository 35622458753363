.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: var(--spacing);
  padding-bottom: var(--spacing);
}

.aboutDetails {
  text-align: left;
  top: 0;
  padding: 0 var(--spacing);
  margin: 0;
}

.brand {
  padding: var(--sm-spacing);
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;
}

.about-section {
  /* margin: var(--spacing) 0; */
  padding: var(--spacing) 10vw;
  list-style-type: none;
  line-height: 2;
  flex-grow: 1;
  width: 100%;
}
